const userData = {
    author: {
        name: 'Anthony TCHA',
    },
    users: [
        {
            username: 'lucien.chang1',
            // Add other user information here
        },
        {
            username: 'SoniaBLV',
            // Add other user information here
        },
        // Add more users as needed
    ],
};

export default userData;