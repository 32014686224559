import React, { useState } from 'react';

const FaqItem = ({ question, answer, isOpen, onToggle }) => (
    <div className="faq-item">
        <p onClick={onToggle} className="faq-question" style={{ cursor: 'pointer' }}>
            {question} <span>{isOpen ? '▲' : '▼'}</span>
        </p>
        {isOpen && <p className="faq-answer">{answer}</p>}
    </div>
);

const FaqSection = ({ title, items }) => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleItem = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="faq-section">
            <h2 className="responsive uppercase no-margin">{title}</h2>
            {items.map((item, index) => (
                <FaqItem
                    key={index}
                    {...item}
                    isOpen={openIndex === index}
                    onToggle={() => toggleItem(index)}
                />
            ))}
        </div>
    );
};

const Faq = () => {
    const sections = [
        {
            title: "Le manga",
            items: [
                { question: "C’est quoi KURO NO MASTER ?", answer: "KURO NO MASTER est un manga interactif..." },
                { question: "Pourquoi lire KURO NO MASTER ?", answer: "Pour vivre une expérience unique..." },
                { question: "Comment lire KURO NO MASTER ?", answer: "Inscrivez-vous et choisissez votre chapitre..." },
                { question: "Comment faire un choix dans les histoires ?", answer: "Les choix apparaissent à des moments clés..." },
                { question: "Puis-je sauvegarder mes choix ?", answer: "Oui, vos choix sont enregistrés..." },
                { question: "Comment puis-je créer un compte ?", answer: "Cliquez sur 'S’authentifier' et suivez les étapes..." },
            ],
        },
        {
            title: "Paiement",
            items: [
                { question: "Combien coûtent les cristaux ?", answer: "Les cristaux sont disponibles à partir de..." },
                { question: "Pourquoi s’abonner ? Quelles sont les contreparties ?", answer: "L'abonnement offre des avantages..." },
                { question: "Comment débloquer un chapitre ?", answer: "Achetez des cristaux pour débloquer..." },
                { question: "Comment relire un chapitre ?", answer: "Les chapitres précédemment lus peuvent être relus..." },
                { question: "Y a-t-il des frais pour lire certains chapitres ?", answer: "Certains chapitres peuvent nécessiter des cristaux..." },
                { question: "Est-ce que les Cristaux achetés et non utilisés peuvent être remboursés ?", answer: "Non, les cristaux ne sont pas remboursables..." },
                { question: "Comment se faire rembourser ?", answer: "Contactez notre support pour plus d'informations..." },
            ],
        },
        {
            title: "Technique",
            items: [
                { question: "Comment signaler un problème technique ?", answer: "Envoyez un email à notre support..." },
                { question: "Les histoires sont-elles mises à jour régulièrement ?", answer: "Oui, nous ajoutons régulièrement de nouveaux chapitres..." },
                { question: "Puis-je laisser des commentaires sur les chapitres ?", answer: "Oui, les commentaires sont ouverts..." },
                { question: "Comment puis-je changer la langue et le mode de traduction ?", answer: "Vous pouvez le faire dans les paramètres..." },
                { question: "Réinitialiser mon mot de passe ?", answer: "Cliquez sur 'Mot de passe oublié'..." },
            ],
        },
        {
            title: "Communauté",
            items: [
                { question: "Y a-t-il une communauté pour discuter de KURO NO MASTER ?", answer: "Oui, rejoignez notre Discord..." },
                { question: "Puis-je partager mes choix et chapitres préférés ?", answer: "Oui, partagez-les sur les réseaux sociaux..." },
                { question: "Y a-t-il des événements spéciaux ou des concours ?", answer: "Nous organisons régulièrement des concours..." },
                { question: "Comment puis-je contribuer en tant qu'artiste ou scénariste ou traducteur ?", answer: "Contactez-nous pour des opportunités..." },
            ],
        },
        {
            title: "Compte",
            items: [
                { question: "Comment mes données sont-elles protégées ?", answer: "Nous prenons la sécurité très au sérieux..." },
                { question: "Puis-je supprimer mon compte ?", answer: "Oui, vous pouvez supprimer votre compte à tout moment..." },
                { question: "Que se passe-t-il si je perds l’accès à mon compte ?", answer: "Contactez le support pour récupérer l'accès..." },
            ],
        },
    ];

    return (
        <section>
            <h1 className="responsive uppercase no-margin">Foire aux questions</h1>
            {sections.map((section, index) => (
                <FaqSection
                    key={index}
                    title={section.title}
                    items={section.items}
                />
            ))}
        </section>
    );
};

export default Faq;
