import React from 'react';
import useImageHover from '../hooks/useImageHover';
import { getCharacterColor } from '../data/chaptersData';
import chapters from '../data/chaptersData';

const ChaptersCard = ({ chapNumber, headline, title, thumbnail, currentChaptersIndex, releaseDate, isUnlocked }) => {
    const [isHovered, handleMouseEnter, handleMouseLeave] = useImageHover();

    return (
        <div
            className={`chapters-card ${isHovered ? 'hovered' : 'no-hover'}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div
                className="chapters-card-image"
                style={{ backgroundImage: `url('${thumbnail}')` }}>
            </div>
            <div className="chapters-card-content">
                <h2 className="no-margin lg bold uppercase">#{chapNumber} — {headline}</h2>
                <p className={`no-margin bold uppercase ${getCharacterColor(currentChaptersIndex)}`}>{title}</p>
                <div className="chapters-bottom-card-content">
                    <p className="no-margin">{releaseDate}</p>
                </div>
            </div>
        </div>
    );
};

export default ChaptersCard;