import React from 'react';
import useImageHover from '../hooks/useImageHover';
import { getCharacterColor } from '../data/chaptersData';
import chapters from '../data/chaptersData';
import ChaptersCard from './ChaptersCard';

function ChaptersList() {
    return (
        <div className="chapters-list">
            {chapters.slice(0, 5).map((chapter) => (
                <ChaptersCard
                    key={chapter.id} // Utilisez un identifiant unique pour chaque chapitre
                    thumbnail={chapter.thumbnail}
                    title={chapter.title}
                    chapNumber={chapter.chapNumber}
                    headline={chapter.headline}
                    releaseDate={chapter.releaseDate}
                    currentChaptersIndex={chapters.indexOf(chapter)} // Index du chapitre dans le tableau
                    isUnlocked={chapter.isUnlocked} // Assurez-vous que cette propriété existe
                />
            ))}
        </div>
    );
}

export default ChaptersList;