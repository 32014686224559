import React from 'react';

function PreviewChapter({ authorName, username, ID, title, currentChapterIndex, showChapterInfo }) {
    return (
        <div className="chapters-preview">
            {showChapterInfo && (
                <div className="chapters-info-container">
                    <p>Réalisé et illustré par </p>
                    <p className="bold lg">{authorName}</p>
                    <p>Co-scénarisé par</p>
                    <p className="bold lg">{username}</p>
                </div>
            )}
        </div>
    );
}

export default PreviewChapter;