import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import chapters from '../data/chaptersData';
import userData from '../data/userData';
import ChaptersPreview from '../components/ChaptersPreview';
import LottieAnimation from '../animation/knm-loader.json';
import Lottie from 'lottie-react';

function Reader() {
    const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [isChapterFinished, setIsChapterFinished] = useState(false);
    const [isMangaFinished, setIsMangaFinished] = useState(false);
    const [fadeOutBlack, setFadeOutBlack] = useState(false);
    const [showChapterInfo, setShowChapterInfo] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isAnimationVisible, setIsAnimationVisible] = useState(true);

    useEffect(() => {
        // Chargement des images de la page courante
        const currentChapter = chapters[currentChapterIndex];
        if (currentChapter && currentChapter.panel) {
            const images = currentChapter.panel.map((imageUrl) => {
                const img = new Image();
                img.src = imageUrl;
                return img;
            });
            Promise.all(images.map((img) => new Promise((resolve) => img.onload = resolve)))
                .then(() => {
                    setTimeout(() => {
                        setIsAnimationVisible(false);
                        setIsLoading(false);
                    }, 2000); // Afficher l'animation pendant 2 secondes
                });
        }
    }, [currentChapterIndex, currentPageIndex]);

    const handleImageClick = () => {
        setShowChapterInfo(false);

        const currentChapter = chapters[currentChapterIndex];
        if (currentChapter && currentChapter.panel) {
            if (currentPageIndex < currentChapter.panel.length - 1) {
                setCurrentPageIndex(currentPageIndex + 1);
            } else {
                setIsChapterFinished(true);
            }
        }
    };

    const handleNextChapter = () => {
        if (currentChapterIndex < chapters.length - 1) {
            setFadeOutBlack(true);
            setTimeout(() => {
                setCurrentChapterIndex(currentChapterIndex + 1);
                setCurrentPageIndex(0);
                setIsChapterFinished(false);
                setFadeOutBlack(false);
                setIsLoading(true);
                setIsAnimationVisible(true);
            }, 1000);
        } else {
            setFadeOutBlack(true);
            setTimeout(() => {
                setIsMangaFinished(true);
            }, 1000);
        }
    };

    return (
        <div>
            {showChapterInfo && (
                <ChaptersPreview
                    authorName={userData.author.name}
                    username={userData.users[0].username}
                    ID={userData.users.id}
                    title={chapters[currentChapterIndex]?.name}
                    currentChapterIndex={currentChapterIndex}
                />
            )}

            {isLoading && isAnimationVisible && (
                <div className="loader-container">
                    <Lottie
                        animationData={LottieAnimation}
                        loop={true}
                        className="lottie-animation"
                    />
                </div>
            )}

            {!isLoading && (
                <div
                    className={`image-container ${isChapterFinished ? 'fade-out' : ''}`}
                    onClick={handleImageClick}
                >
                    {chapters[currentChapterIndex] &&
                        chapters[currentChapterIndex].panel &&
                        chapters[currentChapterIndex].panel[currentPageIndex] && (
                            <img
                                src={chapters[currentChapterIndex].panel[currentPageIndex]}
                                alt="Current page"
                            />
                        )}
                    {fadeOutBlack && <div className="fade-to-black"></div>}
                </div>
            )}

            {isChapterFinished && (
                <section className="to-be-continued fade-out">
                    <div className="text-container">
                        <h1 className="subtitle bold uppercase">À suivre...</h1>
                        <p className="no-margin">
                            Voyez les conséquences de vos actions ou découvrez l'histoire d'un autre personnage.
                        </p>
                        <p className="bold lg">
                            Enregistrez votre progression et recevez gratuitement 10 cristaux à l'inscription.
                        </p>
                        <div className="cta-container">
                            <Link >
                                <button className="primary-button" onClick={handleNextChapter}>Lire la suite</button>
                            </Link >
                            <Link to="/login">
                                <button className="secondary-button">S'inscrire</button>
                            </Link >
                        </div>
                    </div>
                </section>
            )}

            {isMangaFinished && (
                <div className="message-container fade-in">
                    <p>Manga terminé</p>
                </div>
            )}
        </div>
    );
}

export default Reader;