import React, { useState } from 'react';
import { auth, firestore } from '../config/firebase';
import { useNavigate } from 'react-router-dom';

const SignInForm = ({ setCurrentUser }) => {
    const [identifiant, setIdentifiant] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ firebase: '' });
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await auth.signInWithEmailAndPassword(identifiant, password);
            const { user } = userCredential;
            const userDoc = await firestore.collection('users').doc(user.uid).get();
            if (userDoc.exists) {
                const userData = userDoc.data();
                setCurrentUser(userData);
                navigate('/account');
            } else {
                setErrors({ firebase: 'Identifiant ou mot de passe incorrect.' });
            }
        } catch (error) {
            setErrors({ firebase: 'Identifiant ou mot de passe incorrect.' });
            console.error('Erreur lors de la connexion :', error);
        }
    };

    return (
        <form className="form-container" onSubmit={handleLogin}>
            <div className="input-field">
                <label htmlFor="identifiant">Email</label>
                <input
                    type="text"
                    id="identifiant"
                    value={identifiant}
                    onChange={(e) => setIdentifiant(e.target.value)}
                />
            </div>
            <div className="input-field">
                <label htmlFor="password">Mot de passe</label>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            {errors.firebase && <div className="orange italic">{errors.firebase}</div>}
            <button type="submit" className="primary-button">Connexion</button>
        </form>


    );
};

export default SignInForm;