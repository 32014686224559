import React, { useState, useEffect } from 'react';
import CharactersList from '../components/CharactersList';
import ChaptersList from '../components/ChaptersList';
import chapters from '../data/chaptersData';
import { Link } from 'react-router-dom';
import { characters } from '../data/chaptersData';
import Lottie from 'lottie-react';
import LottieAnimation from '../animation/knm-loader.json';

function Hero({ chapter }) {
    const character = characters.find(char => char.link === chapter.characterLink);

    useEffect(() => {
        const titleElement = document.querySelector('.responsive');
        if (titleElement && character) {
            titleElement.style.background = character.titleGradient;
            titleElement.style.backgroundClip = 'text';
            titleElement.style.WebkitTextFillColor = 'transparent';
        }
    }, [character]);

    return (
        <div className="hero-image" style={{ backgroundImage: `url(${chapter.thumbnail})` }}>
        </div>
    );
}

function Home({ currentUser, onLogout }) {
    const [currentChapter, setCurrentChapter] = useState(chapters[0]);
    const [isCheckingUser, setIsCheckingUser] = useState(true);

    useEffect(() => {
        if (!currentUser) {
            setIsCheckingUser(false);
            return;
        }

        // Simulez une vérification d'utilisateur asynchrone
        const checkUser = async () => {
            await new Promise(resolve => setTimeout(resolve, 500));
            setIsCheckingUser(false);
        };

        checkUser();
    }, [currentUser]);

    useEffect(() => {
        const footer = document.querySelector('footer');
        if (footer) {
            if (isCheckingUser) {
                footer.classList.add('hidden-footer');
            } else {
                footer.classList.remove('hidden-footer');
            }
        }
    }, [isCheckingUser]);

    if (isCheckingUser) {
        return (
            <div className="loader-container">
                <Lottie
                    animationData={LottieAnimation}
                    loop={true}
                    className="lottie-animation"
                />
            </div>
        );
    }

    return (
        <div>
            <section>
                <Hero chapter={currentChapter} />
                <div className="text-container hero">
                    {currentUser ? (
                        <>
                            <h1 className="responsive uppercase no-margin">L'ère du temps</h1>
                            <p>Existant depuis l’aube de l’humanité, les Montres de Pouvoir octroient des capacités altérant la réalité à leurs Porteurs, des femmes et hommes d'exception dont les noms sont encore gravés dans les mémoires.</p>
                            <p className="bold lg">Cinq destinées où vos choix écriront l'histoire.</p>
                            <div className="cta-container">
                                <Link to={`/read/${currentChapter.id}`}>
                                    <button className="primary-button">Reprendre la lecture</button>
                                </Link>
                                <Link to='/chapters'>
                                    <button className="secondary-button">Voir tous les chapitres</button>
                                </Link>
                            </div>
                        </>
                    ) : (
                        <>
                            <h1 className="responsive uppercase no-margin">Le manga interactif</h1>
                            <p>Existant depuis l’aube de l’humanité, les Montres de Pouvoir octroient des capacités altérant la réalité à leurs Porteurs, des femmes et hommes d'exception dont les noms sont encore gravés dans les mémoires.</p>
                            <p className="bold lg">Cinq destinées où vos choix écriront l'histoire.</p>
                            <div className="cta-container">
                                <Link to="/read">
                                    <button className="primary-button">Commencer à lire</button>
                                </Link>
                                <Link to="/login">
                                    <button className="secondary-button">S'authentifier</button>
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </section>
            <section id="characters">
                <div className="text-container">
                    <h2 className="responsive uppercase no-margin">Les personnages</h2>
                    <p>Découvrez un monde où la maîtrise du temps, l’espace, du destin, des sens et de la vie est à portée de main.</p>
                    <p className="bold lg">Aujourd’hui, vous avez été choisis pour être leurs Porteurs.</p>
                </div>
                <CharactersList />
            </section>
            <section className="chapters-section" id="chapters">
                <div className="text-container">
                    <h2 className="responsive uppercase no-margin">Les chapitres</h2>
                </div>
                <ChaptersList />
            </section>
            <div className='centered'>
                <Link to="/chapters" >
                    <button className='secondary-button'>Voir tous les chapitres</button>
                </Link>
            </div>
        </div>
    );
}

export default Home;