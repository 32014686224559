import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import LottieAnimation from '../animation/knm-loader.json';

const Account = ({ currentUser }) => {
    const [isCheckingUser, setIsCheckingUser] = useState(true);

    useEffect(() => {
        // Simulez une vérification d'utilisateur asynchrone
        const checkUser = async () => {
            // Simuler un délai pour représenter la vérification de l'utilisateur
            await new Promise(resolve => setTimeout(resolve, 500));
            setIsCheckingUser(false);
        };

        checkUser();
    }, []);

    useEffect(() => {
        const footer = document.querySelector('footer');
        if (footer) {
            if (isCheckingUser) {
                footer.classList.add('hidden-footer');
            } else {
                footer.classList.remove('hidden-footer');
            }
        }
    }, [isCheckingUser]);

    if (isCheckingUser) {
        return (
            <div className="loader-container">
                <Lottie
                    animationData={LottieAnimation}
                    loop={true}
                    className="lottie-animation"
                />
            </div>
        );
    }

    if (!currentUser) {
        return (
            <section>
                <h1 className="responsive uppercase no-margin">Ah, vous êtes déconnectés.</h1>
                <p>Vous devez vous connecter pour accéder à cette page.</p>
                <Link to="/login">
                    <button className="secondary-button">S'authentifier</button>
                </Link>
            </section>
        );
    }

    return (
        <section>
            <div className="welcome-page">
                <h1 className="responsive uppercase no-margin">Bienvenue</h1>
                <h2 className="responsive no-margin">Bienvenue {currentUser.displayName} !</h2>
                <p>Commencez à explorer mon univers dès maintenant !</p>
            </div>
        </section>
    );
};

export default Account;
