// Importez le package firebase complet
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

// Configurez votre application Firebase
const firebaseConfig = {
    apiKey: "AIzaSyAYv1fNFtfKnzHvA4Pz7r9BbSZcGL-g1RU",
    authDomain: "kuro-no-master.firebaseapp.com",
    projectId: "kuro-no-master",
    storageBucket: "kuro-no-master.appspot.com",
    messagingSenderId: "472936399078",
    appId: "1:472936399078:web:7d26721470fcead6b1bb27",
    measurementId: "G-SME85BYQ4G"
};

// Initialisez l'application Firebase
firebase.initializeApp(firebaseConfig);

// Récupérez les instances des services Firebase que vous utiliserez
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const analytics = firebase.analytics();

// Exportez l'application Firebase pour qu'elle puisse être utilisée ailleurs dans votre projet
export default firebase;