import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { auth, firestore } from './config/firebase';
import { useBreakpointObserver } from './hooks/useBreakpointObserver';
import './App.css';

import Header from './components/Header';
import Footer from './components/Footer';

import Home from './pages/Home';
import Read from './pages/Read';
import LoginForm from './pages/Login';
import Shop from './pages/Shop';
import History from './pages/History';
import Characters from './pages/Characters';
import Chapters from './pages/Chapters';
import Account from './pages/Account';
import Premium from './pages/Premium';
import About from './pages/About'
import Contact from './pages/Contact';
import Faq from './pages/Faq';

const breakPoints = {
  mobile: "(max-width: 479px)",
  tablet: "(min-width: 480px) and (max-width: 767px)",
  laptop: "(min-width: 768px) and (max-width: 1023px)",
  desktop: "(min-width: 1024px)",
};

const App = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = firestore.collection('users').doc(user.uid);
        const doc = await userRef.get();
        if (doc.exists) {
          setCurrentUser(doc.data());
        }
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe(); // Cleanup listener
  }, []);

  return (
    <Router>
      <div className={`App ${useBreakpointObserver(breakPoints)}`}>
        <AppContent currentUser={currentUser} setCurrentUser={setCurrentUser} />
      </div>
    </Router>
  );
};

const AppContent = ({ currentUser, setCurrentUser }) => {
  const { pathname } = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const closeDropdown = () => setIsDropdownOpen(false);
  const toggleDropdown = () => setIsDropdownOpen(prev => !prev);

  useEffect(() => {
    window.scrollTo(0, 0);
    closeDropdown(); // Ferme le dropdown lors du changement de page
  }, [pathname]);

  return (
    <>
      <Header currentUser={currentUser} setCurrentUser={setCurrentUser} closeDropdown={closeDropdown} isDropdownOpen={isDropdownOpen} toggleDropdown={toggleDropdown} />
      <Routes>
        <Route path="/" element={<Home currentUser={currentUser} />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/history" element={<History />} />
        <Route path="/read/:chapterId" element={<Read />} />
        <Route path="/login" element={<LoginForm setCurrentUser={setCurrentUser} />} />
        <Route path="/characters/:characterId" element={<Characters />} />
        <Route path="/chapters" element={<Chapters />} />
        <Route path="/account" element={<Account currentUser={currentUser} />} />
        <Route path="/premium" element={<Premium currentUser={currentUser} />} /> {/* Passer currentUser ici */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
      {!pathname.startsWith('/read') && <Footer />}
    </>
  );
};

export default App;