import React from 'react';

const PurchaseTable = () => {
    const data = [
        ['0,99 (minimum)', 2, 0, 0],
        ['1,99', 5, 0, 1],
        ['2,99', 7, 1, 1],
        ['4,99', 10, 1, 2],
        ['9,99', 25, 3, 5],
        ['19,99', 50, 7, 10],
        ['49,99', 125, 17, 29],
        ['99,99', 250, 35, 50],
        ['139,99', 350, 50, 70]
    ];

    const headers = [
        'Prix',
        'Cristaux achetés',
        'Chap. déblocables',
        'Chap. déblocables (abonné)',
    ];

    return (
        <table>
            <thead>
                <tr>
                    {headers.map((header, index) => (
                        <th key={index}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => (
                    <tr key={index}>
                        {row.map((cell, cellIndex) => (
                            <td key={`${index}-${cellIndex}`}>{cell}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const RewardTable = () => {
    const data = [
        ['1', '0,14', '0,03', '0,28', '0', '0'],
        ['3,5', '0,5', '0,13', '1', '0', '0'],
        ['7', '1', '0,23', '2', '0', '0'],
        ['28', '4', '1', '8', '1', '1'],
        ['35', '5', '1,25', '10', '1', '2'],
        ['365', '52', '12', '104', '14', '17'],
        ['875', '125', '28', '250', '35', '50'],
        ['1307', '186', '43', '350', '50', '70']
    ];

    const headers = [
        'Jours',
        'Semaines',
        'Mois',
        'Cristaux générés',
        'Chap. déblocables',
        'Chap. déblocable (abonné)',
    ];

    return (
        <table>
            <thead>
                <tr>
                    {headers.map((header, index) => (
                        <th key={index}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => (
                    <tr key={index}>
                        {row.map((cell, cellIndex) => (
                            <td key={`${index}-${cellIndex}`}>{cell}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export { PurchaseTable, RewardTable };