import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { characters } from '../data/chaptersData';
import useImageHover from '../hooks/useImageHover';

function CharacterCard({ link, name, title, cardImage, cardGradient }) {
    const [isHovered, handleMouseEnter, handleMouseLeave] = useImageHover();
    const isMobileOrTablet = useMediaQuery({ query: '(max-width: 767px)' });

    let hoverGradient = cardGradient.replace('00 40%', 'FF 90%');
    let adjustedGradient = cardGradient;

    if (isMobileOrTablet) {
        adjustedGradient = cardGradient.replace('0deg', '-90deg').replace('40%', '100%');
        hoverGradient = hoverGradient.replace('0deg', '-90deg');
    }

    return (
        <Link to={`/characters/${link}`}>
            <div className={`character-card ${isHovered ? '' : 'no-hover'}`}
                style={{ backgroundImage: `url(${cardImage})` }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <div className="character-card-overlay" style={{ background: isHovered ? hoverGradient : adjustedGradient }}></div>
                <div className="character-card-content">
                    <p className="bold lg uppercase no-margin">{name}</p>
                    <p className="uppercase no-margin">{title}</p>
                </div>
            </div>
        </Link>
    );
}
function CharacterList() {
    return (
        <div className="character-card-list">
            {characters.map((character, index) => (
                <CharacterCard
                    key={index}
                    name={character.name}
                    title={character.title}
                    cardImage={character.cardImage}
                    cardGradient={character.cardGradient}
                    link={character.link}
                />
            ))}
        </div>
    );
}

export default CharacterList;
