import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../images/logos/logo-emblem-wht.png';

const FooterSocialLink = ({ href, id, alt, icon }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={icon} className="icon" id={id} alt={alt} size="xl" />
    </a>
);

const LogoLink = ({ href }) => (
    <img src={logo} className="logo-footer" alt="Logo de KURO NO MASTER" />
);

const Footer = () => (
    <footer className="footer-container">
        <div className="footer-links-container">
            <div className="footer-links-rgpd-container">
                <Link to="/contact" className="bold uppercase">Me contacter</Link>
                <Link to="/faq" className="bold uppercase">Foire aux questions</Link>
                <Link to="/about" className="bold uppercase">À propos</Link>
            </div>
            <div className="footer-links-social-container">
                <FooterSocialLink href="https://twitter.com/kuronomaster_" alt="Logo Twitter" icon={['fab', 'x-twitter']} />
                <FooterSocialLink href="https://www.instagram.com/kuronomaster/" alt="Logo Instagram" icon={['fab', 'instagram']} />
                <FooterSocialLink href="https://discord.gg/G96hbSFW" alt="Logo Discord" icon={['fab', 'discord']} />
                <FooterSocialLink href="https://www.reddit.com/r/kuronomaster/" alt="Logo Reddit" icon={['fab', 'reddit']} />
            </div>
        </div>
        <Link to="/"><LogoLink /></Link>
        <p>© {new Date().getFullYear()} KURO NO MASTER</p>
    </footer>
);

export default Footer;