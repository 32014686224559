import React from 'react';
import { Link, Navigate } from 'react-router-dom';

const Premium = ({ currentUser }) => {
    const isSubscribed = currentUser && currentUser.isSubscribed; // Vérifier l'abonnement

    if (!isSubscribed) {
        return <Navigate to="/shop" />;
    }

    return (
        <section>
            <h1 className="responsive uppercase no-margin">Premium</h1>
            <div className="global-stats">
                <h2>Statistiques globales</h2>
                <select>
                    <option>Monde</option>
                    <option>Par région</option>
                    <option>Par pays</option>
                </select>
                <table>
                    <thead>
                        <tr>
                            <th>Statistique</th>
                            <th>Valeur</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Temps de lecture moyen</td>
                            <td>5 minutes</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="arborescence">
                <h2>Arborescence, Roman & Commentaires</h2>
                <select>
                    <option>Tous les chapitres</option>
                    <option>Chapitres débloqués</option>
                    <option>Chapitres verrouillés</option>
                </select>
                <div className="chapter-cards">
                    <div className="chapter-card">
                        <h3>L’ère du temps</h3>
                        <p>Partie: Maître du Temps</p>
                        <p>ID: #1 – 19 septembre 2024</p>
                        <p>État: Déverrouillé</p>
                        <div className="buttons">
                            <Link to="/read"><button>Lire</button></Link>
                            <button>Arborescence</button>
                            <button>Roman</button>
                            <button>Manga commenté</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="artbook">
                <h2>Artbook</h2>
                <select>
                    <option>Illustrations</option>
                    <option>Vidéos</option>
                    <option>Musiques</option>
                </select>
                <div className="gallery">
                    <h3>Galerie</h3>
                </div>
            </div>

            <button onClick={() => {/* Logique de résiliation */ }}>
                Résilier l’abonnement
            </button>
            <p>Aucun remboursement, perte des avantages 48h après confirmation de la résiliation.</p>
        </section>
    );
};

export default Premium;