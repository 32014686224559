import React, { useState } from 'react';
import SignInForm from '../components/signIn';
import SignUpForm from '../components/signUp';

const LoginForm = ({ setCurrentUser }) => {
    const [isLogin, setIsLogin] = useState(true);

    const toggleLoginMode = () => {
        setIsLogin((prevState) => !prevState);
    };

    return (
        <section>
            <div className="text-container">
                <h1 className="responsive uppercase no-margin">{isLogin ? 'Connexion' : 'Inscription'}</h1>
            </div>
            <div>
                {isLogin ? (
                    <SignInForm setCurrentUser={setCurrentUser} />
                ) : (
                    <SignUpForm setCurrentUser={setCurrentUser} />
                )}
                <div className='form-container'>
                    <p className="italic center no-margin">{isLogin ? 'Pas de compte ?' : 'Déjà un compte ?'}</p>
                    <button type="button" className="secondary-button" onClick={toggleLoginMode}>
                        {isLogin ? 'S\'inscrire' : 'Se connecter'}
                    </button>
                </div>
            </div>
        </section>
    );
};

export default LoginForm;