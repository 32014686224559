import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../config/firebase';
import logo from '../images/logos/logo-originel-transparency.png';

const HeaderButton = ({ href, text, onClick }) => (
    <button
        onClick={onClick ? onClick : () => window.location.href = href}
        className={text === 'S\'authentifier' || text === 'Se déconnecter' ? 'secondary-button' : 'button'}>
        {text}
    </button>
);

const HeaderButtonList = ({ links }) => (
    links.map((link, index) => <HeaderButton key={index} {...link} />)
);

const DropdownButton = ({ isOpen, toggleDropdown, isAuthenticated, setCurrentUser, closeDropdown }) => {
    const navigate = useNavigate();

    const handleLogout = async () => {
        await auth.signOut();
        setCurrentUser(null);
        closeDropdown();
        navigate('/');
    };

    const links = [
        { href: "/account", text: "Mon compte", requiresAuth: true },
        { href: "/shop", text: "Les cristaux", requiresAuth: false },
        { href: "/chapters", text: "Les chapitres", requiresAuth: false },
        { href: "/contact", text: "Me contacter", requiresAuth: false },
        { href: "/login", text: "S'authentifier", requiresAuth: false },
        { href: null, text: "Se déconnecter", requiresAuth: true, onClick: handleLogout },
    ];

    return (
        <div className="dropdown-container">
            <div className={`dropdown ${isOpen ? 'open' : ''}`}>
                <div className="dropdown-icon-container" onClick={toggleDropdown}>
                    <FontAwesomeIcon icon={isOpen ? "fa-solid fa-times" : "fa-solid fa-bars"} size="xl" />
                </div>
                {isOpen && (
                    <div className="dropdown-menu">
                        <HeaderButtonList links={links.filter(link => link.requiresAuth === isAuthenticated)} />
                    </div>
                )}
            </div>
        </div>
    );
};

const Header = ({ currentUser, setCurrentUser }) => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const toggleDropdown = () => setIsOpen(prev => !prev);
    const closeDropdown = () => setIsOpen(false);

    useEffect(() => {
        closeDropdown(); // Ferme le dropdown lors du changement de page
    }, [location.pathname]);

    return (
        <>
            <header className="header">
                <Link to="/" onClick={closeDropdown}>
                    <img src={logo} className="logo-header" alt="Logo de KURO NO MASTER" />
                </Link>
                <DropdownButton
                    isOpen={isOpen}
                    toggleDropdown={toggleDropdown}
                    isAuthenticated={!!currentUser}
                    setCurrentUser={setCurrentUser}
                    closeDropdown={closeDropdown}
                />
            </header>
            {isOpen && <div className="overlay" onClick={closeDropdown}></div>}
        </>
    );
};

export default Header;