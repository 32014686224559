import { useState, useEffect } from 'react';

export function useBreakpointObserver(breakpoints) {
  const [breakpoint, setBreakpoint] = useState('');

  useEffect(() => {
    const mediaQueries = Object.keys(breakpoints).map(key => {
      const mediaQuery = window.matchMedia(breakpoints[key]);
      const handleChange = () => {
        if (mediaQuery.matches) {
          setBreakpoint(key);
        }
      };
      mediaQuery.addEventListener('change', handleChange);
      return { mediaQuery, handleChange };
    });

    const initialBreakpoint = Object.keys(breakpoints).find(key => window.matchMedia(breakpoints[key]).matches);
    if (initialBreakpoint) {
      setBreakpoint(initialBreakpoint);
    }

    return () => {
      mediaQueries.forEach(({ mediaQuery, handleChange }) => {
        mediaQuery.removeEventListener('change', handleChange);
      });
    };
  }, [breakpoints]);

  return breakpoint;
}
