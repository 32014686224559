import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CharactersList from '../components/CharactersList';
import { characters } from '../data/chaptersData';
function Characters() {
    const { characterId } = useParams();
    const character = characters.find((char) => char.link === characterId);

    useEffect(() => {
        const titleElement = document.querySelector('.responsive');
        if (titleElement && character) {
            titleElement.style.background = character.titleGradient;
            titleElement.style.backgroundClip = 'text';
            titleElement.style.WebkitTextFillColor = 'transparent';
        }
    }, [character]);

    return (
        <div>
            <section>
                <div className="hero-image" style={{ backgroundImage: `url(${character.imagePage})` }}></div>
                <div className="text-container hero">
                    <h1 className="responsive uppercase no-margin">{character.name}</h1>
                    <h2 className="responsive uppercase no-margin">{character.title}</h2>
                    <p>{character.description}</p>
                    <p className="bold lg">{character.descriptionPitch}</p>
                </div>
            </section>
            <CharactersList />
        </div>
    );
}

export default Characters;