import React, { useState } from 'react';
import { PurchaseTable, RewardTable } from '../components/PurchaseTable';

const FaqItem = ({ title, content, isOpen, onToggle }) => (
    <div className="faq-item">
        <h2 onClick={onToggle} className="responsive uppercase no-margin" style={{ cursor: 'pointer' }}>
            {title} <span>{isOpen ? '▲' : '▼'}</span>
        </h2>
        {isOpen && <div className="faq-answer">{content}</div>}
    </div>
);

const About = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const documents = [
        {
            title: "Mentions légales",
            content: (
                <div>
                    <p>En vertu de l’Article de la Loi n° 2004-575 du 21 juin 2004 pour la confiance en l’économie numérique, il est précisé dans cette article l’identité des différents intervenants informer les utilisateurs du site KURO NO MASTER sur l’identité des intervenants impliqués dans sa création et son suivi.</p>
                    <h2>Propriété intellectuelle</h2>
                    <p>Le contenu du site, comprenant les histoires et les illustrations, est la propriété intellectuelle exclusive de KURO NO MASTER.</p>
                    <h2>Edition du site</h2>
                    <p>Le présent site, accessible sur l'URL : <a href="https://www.kuronomaster.com" className="bold underline">www.kuronomaster.com</a> (le « Site »), est édité par :</p>
                    <ul>
                        <li>Raison sociale : KURO NO MASTER</li>
                        <li>Adresse du siège social : -</li>
                        <li>E-mail : <a href="mailto:contact@kuronomaster.com" className="bold underline">contact@kuronomaster.com</a></li>
                        <li>N° de téléphone : -</li>
                        <li>Nom du directeur de publication : Anthony TCHA</li>
                        <li>Capital social : -</li>
                        <li>Registre du commerce et des sociétés : -</li>
                        <li>Numéro SIRET : -</li>
                        <li>Objet social : La conception, la création, la production et la diffusion de mangas interactifs, sous forme de webapps en ligne ou de toute autre plateforme numérique permettant la lecture et l'interaction avec le contenu</li>
                    </ul>
                    <h2>Hébergement</h2>
                    <p>Le Site est hébergé par : HOSTINGER, dont le siège social est situé HOSTINGER INTERNATIONAL LTD, 61 Lordou Vironos Street, 6023 Larnaca, Chypre, joignable par le moyen suivant : <a href="https://www.hostinger.fr/contact" className="bold underline">https://www.hostinger.fr/contact</a></p>
                    <h2>Directeur de publication</h2>
                    <p>Le Directeur de la publication du Site est Anthony TCHA, représentant légal de KURO NO MASTER.</p>
                </div>
            ),
        },
        {
            title: "Politique de confidentialité",
            content: (
                <div>
                    <h1 className="subtitle">Politique de confidentialité</h1>
                    <p>KURO NO MASTER attache une grande importance à la protection de votre vie privée, chers co-scénaristes. Cette politique détaille les informations que le site collecte et comment ils sont utilisés.</p>
                    <h2>Informations personnelles</h2>
                    <p>Lors de l’utilisation de la plateforme, des données personnelles telles que votre nom, votre adresse e-mail, et des informations liées à votre lecture et à votre compte peuvent être collectées. Ces données sont essentielles pour la création et la gestion de votre compte KURO NO MASTER.</p>
                    <h2>Informations d’utilisation</h2>
                    <p>Le site collecte également des données sur vos interactions avec le site, incluant les choix que vous effectuez lors de la lecture interactive des chapitres. Ces informations contribuent à améliorer l’expérience utilisateur et le contenu proposé.</p>
                    <h2>Protection des données</h2>
                    <p>Des mesures de sécurité strictes sont mises en place pour protéger vos informations contre tout accès non autorisé, divulgation, altération ou destruction. Vos données sont stockées de manière sécurisée et ne sont accessibles qu’aux personnes autorisées.</p>
                    <h2>Durée de conservation des données</h2>
                    <p>Les données seront conservées pendant la durée de l’activité de votre compte KURO NO MASTER. Cela signifie que tant que votre compte est actif, vos données personnelles seront stockées de manière sécurisée. Si vous décidez de fermer votre compte, vos informations seront supprimées conformément aux pratiques de gestion des données.</p>
                    <h2>Partage d’informations</h2>
                    <p>Les informations personnelles ne sont pas partagées avec d’autres utilisateurs ni des tiers sans votre consentement, sauf si cela est nécessaire pour fournir les services ou si requis par la loi.</p>
                    <h2>Cookies et technologies similaires</h2>
                    <p>Des cookies et d’autres technologies similaires sont utilisés pour améliorer votre expérience sur KURO NO MASTER. Ces outils aident à comprendre votre utilisation du site et à personnaliser votre expérience.</p>
                    <h2>Droits des utilisateurs</h2>
                    <p>En tant qu’utilisateur de KURO NO MASTER, vous bénéficiez de plusieurs droits en matière de protection des données : vous avez le droit d’accéder, de rectifier ou de supprimer vos informations personnelles.</p>
                    <p>Si vous souhaitez exercer l’un de ces droits ou si vous avez des questions sur le traitement de vos données, veuillez contacter l'auteur à l’adresse <a href="mailto:contact@kuronomaster.com" className="bold underline">contact@kuronomaster.com</a>.</p>
                    <h2>Modifications de la politique de confidentialité</h2>
                    <p>Cette politique peut être mise à jour de temps à autre pour refléter les changements dans les pratiques. Toute modification importante sera signalée sur le site web.</p>
                    <p>En utilisant KURO NO MASTER, vous acceptez les termes de la politique de confidentialité. Merci de la lire attentivement et de me contacter pour toute préoccupation ou question.</p>
                    <h2>Liens vers d’autres sites web</h2>
                    <p>Le site de KURO NO MASTER peut contenir des liens vers d’autres sites web qui ne sont pas détenus ou contrôlés par KURO NO MASTER. Je ne me tiens pas responsable de ces sites ou de leurs pratiques de confidentialité.</p>
                    <p>Soyez attentifs en quittant le site et lisez les déclarations de confidentialité de chaque site susceptible de collecter des informations personnelles.</p>
                </div>),
        },
        {
            title: "Conditions générales d'utilisation",
            content: (
                <div>
                    <p>Bienvenue sur KURO NO MASTER, le manga interactif. En accédant et en utilisant le site, vous acceptez les présentes Conditions Générales d'Utilisation (CGU). Veuillez les lire attentivement.</p>
                    <h2>Lire le premier chapitre</h2> <p>L’utilisateur invité n’a pas de compte et n’est pas identifié. L'utilisateur invité peut lire uniquement le premier chapitre de la partie Maître du Temps. Avant de commencer la lecture, l'utilisateur doit choisir le mode de traduction et sa langue. Il devra décider de s’inscrire ou de continuer la lecture. L'utilisateur peut revenir en arrière uniquement lors des vignettes sans choix, ce qui est pratique en cas d'erreur ou d'incompréhension. Cependant, il est impossible de revenir en arrière après avoir effectué un choix. Si l’utilisateur s’inscrit, ses préférences linguistiques seront enregistrées sur son compte. Un rappel de l’inscription lui apparaîtra une fois le premier chapitre terminé. Il pourra obtenir une récompense de 7 cristaux sur son compte, pour débloquer le chapitre suivant ou celui d'un autre personnage.</p>

                    <h2>S’authentifier</h2 >
                    <p>Pour s’authentifier, l'utilisateur clique sur le bouton « S’authentifier » et est redirigé sur la page de connexion. Pour se connecter, l'utilisateur utilise son adresse email et son mot de passe associé. Ssi l'utilisateur l’a oublié, il peut le réinitialiser en renseignant son adresse email. Pour s’inscrire, l'utilisateur remplit les champs requis, coche la case d’acceptation et de prise de connaissance de la politique de confidentialité et des mentions légales. Pour valider l'inscription, l'utilisateur doit cliquer sur « S’inscrire » et activer son compte en cliquant sur un lien envoyé par email. La validation du compte par mail est obligatoire pour que le compte ne soit pas supprimé.L’utilisateur ne pourra pas enregistrer sa progression s’il ne valide pas son compte. Il y est affiché la mention de la suppression du compte au bout de 7 jours de non-validation et l’obtention de 7 cristaux gratuits une fois son compte activé.</p >

                    <h2>Choisir le mode de traduction</h2 >
                    <p>L'utilisateur peut choisir le mode de traduction avant la lecture du premier chapitre.Il a la possibilité de conserver ce paramètre pour les futures lectures en cochant une case ou de le modifier dans la section des paramètres de son compte. Avant chaque lecture de chapitre, l'utilisateur peut choisir le mode de traduction :</p>
                    <ul><li>Version originale (langue originelle des personnages).</li>
                        <li>Version originale sous - titrée (par défaut, langue originelle des personnages avec traduction).</li>
                        <li>Version traduite (traduction de tous les textes dans la langue de l'utilisateur).</li></ul>
                    <p>Note : les histoires des parties Maître du Temps, de l’Espace et du Destin sont en grande partie en français et les histoires des parties Maître des Sens et de la Vie sont en grande partie en anglais.</p>

                    <h2>Choisir la langue</h2>
                    <p>L'utilisateur peut sélectionner la langue de son choix pour l'affichage sur la page d'accueil et la webapp de lecture de Kuro no Master depuis les paramètres linguistiques de son compte. Une fenêtre de confirmation s'affiche dans la langue choisie par l'utilisateur.En l'absence de validation, l'utilisateur retourne vers la page de sélection de langue, préservant sa préférence linguistique initiale.Les langues, par ordre de priorité de développement décroissant :</p>
                    <ul>
                        <li>Français (French)</li>
                        <li>English </li>
                        <li>Español (Spanish)</li>
                        <li>日本語 (Japanese)</li>
                        <li>中文 (Chinese)</li>
                        <li>русский (Russian)</li>
                        <li>Deutsch (German)</li>
                    </ul>
                    <h2>Enregistrer la progression de lecture du chapitre en cours</h2>

                    <p>Les cookies permettent de sauvegarder la progression de lecture du chapitre en cours.Pour le premier chapitre, les cookies peuvent être utilisés pour enregistrer la progression de lecture. Si l'utilisateur accepte les cookies, il pourra reprendre sa lecture là où il en était s'il ferme puis revient sur l'application de webapp.S’il n’accepte pas les cookies et qu’il ferme la page lors de sa lecture, il devra recommencer le chapitre depuis le début.</p>

                </div >
            ),
        },
        {
            title: "Conditions générales de vente",
            content: (
                <div>
                    <h2>Déblocage de chapitres</h2>
                    <p>Les chapitres de KURO NO MASTER sont disponibles à l'achat pour tous les utilisateurs inscrits. Chaque chapitre peut être débloqué moyennant un paiement de 7 cristaux. Les utilisateurs peuvent choisir les chapitres à débloquer en cliquant sur les cartes de chapitres verrouillés et en confirmant leur achat.</p>
                    <h2>Possession d'un solde de cristaux</h2>
                    <p>Chaque utilisateur inscrit détient un solde de cristaux, la monnaie virtuelle servant au déblocage des chapitres de KURO NO MASTER. Les chapitres coûtent 7 cristaux pour tous les utilisateurs connectés. Il est à noter qu'il est impossible d'échanger des cristaux entre utilisateurs.</p>
                    <h2>Recommencer un chapitre</h2>
                    <p>L'utilisateur peut choisir de recommencer un chapitre commencé en échange de 5 cristaux. Si l'utilisateur est abonné, alors le montant pour recommencer un chapitre terminé est de 0 cristaux.</p>
                    <h2>Essai gratuit</h2>
                    <p>À l'inscription, chaque nouvel utilisateur activé reçoit gratuitement 7 cristaux. Ces cristaux peuvent être utilisés pour réécrire la fin du premier chapitre ou débloquer un chapitre de KURO NO MASTER, offrant ainsi aux utilisateurs la possibilité de découvrir le contenu de manière gratuite.</p>
                    <h2>Abonnement</h2>
                    <p>Les utilisateurs ont la possibilité de souscrire à un abonnement mensuel pour accéder à des avantages exclusifs :</p>
                    <h3>3,99 €/mois</h3>
                    <ul>
                        <li>Baisse du prix des chapitres de 7 à 5 cristaux</li>
                        <li>Récupération automatique des cristaux</li>
                        <li>Statistiques globales</li>
                        <li>Chapitres rejouables gratuitement</li>
                    </ul>
                    <h3>4,99 €/mois</h3>
                    <ul>
                        <li>Commentaires de l’auteur</li>
                        <li>Accès en avant-première</li>
                        <li>Script des chapitres</li>
                        <li>Arborescences des chapitres</li>
                    </ul>
                    <h3>9,99 €/mois</h3>
                    <ul>
                        <li>Fichiers originaux</li>
                        <li>Fichiers musicaux</li>
                        <li>Brouillons et ébauches WIP</li>
                        <li>Fichiers archives évolution</li>
                    </ul>
                    <h2>Tarification des Abonnements</h2>
                    <p>Les abonnements sont disponibles à partir de 3,99 € par mois et offrent différentes options en fonction du prix mensuel choisi par l'utilisateur. Les abonnés bénéficient également d'une réduction sur les prix des chapitres débloqués.</p>
                    <h2>Acheter des cristaux</h2>
                    <p>Tous les utilisateurs connectés ont la possibilité d’acheter des cristaux, offrant ainsi la flexibilité de déverrouiller le contenu rapidement. Les prix indicatifs varient, et pour débloquer l’intégralité de KURO NO MASTER, l’utilisateur devrait acheter et dépenser au moins 350 cristaux, équivalant à 140 €, soit 28 € par partie complète.</p>
                    <h2>Tableau de tarification d’achat de cristaux</h2>
                    <PurchaseTable />
                    <h2>Tableau de génération de cristaux</h2>
                    <RewardTable />
                    <h2>Génération des cristaux</h2>
                    <p>Chaque semaine, les utilisateurs connectés peuvent générer manuellement 2 cristaux en se rendant sur la page d’accueil de KURO NO MASTER à partir de 23:59 tous les samedis. Un décompte en temps réel basé sur le fuseau horaire lors de la création du compte est appliqué.</p>
                    <h2>Délai de récupération des Cristaux</h2>
                    <p>Si les cristaux générés ne sont pas récupérés dans la semaine, ils seront perdus le samedi suivant à 23:59. Il est techniquement possible de maximiser la génération en récupérant les cristaux juste avant le samedi à 23:59 et de récupérer les cristaux de la semaine suivante le dimanche à 00:01.</p>
                    <p>En conclusion, pour bénéficier de l’intégralité de KURO NO MASTER gratuitement, les utilisateurs devraient récupérer des cristaux chaque semaine pendant une période prolongée, ajoutant un élément de progression durable au système de gestion des cristaux.</p>
                </div>
            ),
        },
    ];

    const toggleItem = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <section>
            <h1 className="responsive uppercase no-margin">À propos</h1>
            {documents.map((doc, index) => (
                <FaqItem
                    key={index}
                    title={doc.title}
                    content={doc.content}
                    isOpen={openIndex === index}
                    onToggle={() => toggleItem(index)}
                />
            ))}
        </section>
    );
};

export default About;