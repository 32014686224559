import React from 'react';
import { Link } from 'react-router-dom';

const Shop = () => {
    const crystalOptions = [2, 5, 7, 10, 25, 50, 125, 250, 350];
    const subscriptionOptions = [
        { price: 0.00, benefits: ["Accès illimité aux chapitres"] },
        { price: 3.99, benefits: ["Prix des chapitres abaissés à 5 cristaux"] },
        { price: 4.99, benefits: ["Accès aux chapitres exclusifs"] },
        { price: 9.99, benefits: ["Remises sur achats de cristaux"] },
    ];

    return (
        <section>
            <h1 className="responsive uppercase no-margin">Achat et abonnement</h1>
            <h2 className="responsive uppercase no-margin">Achat de Cristaux</h2>
            <div className="card-container">
                {crystalOptions.map((amount) => (
                    <div className="card" key={amount}>
                        <h3>{amount} Cristaux</h3>
                        <span>Comparatif: {amount} Cristaux = X€</span>
                        <Link to="/login">
                            <button>Acheter</button>
                        </Link>
                    </div>
                ))}
            </div>

            <h2>Abonnement</h2>
            <div className="card-container">
                {subscriptionOptions.map((option) => (
                    <div className="card" key={option.price}>
                        <h3>{option.price} €/mois</h3>
                        <ul>
                            {option.benefits.map(benefit => (
                                <li key={benefit}>{benefit}</li>
                            ))}
                        </ul>
                        <Link to="/login">
                            <button>S’abonner</button>
                        </Link>
                    </div>
                ))}
            </div>

            <Link to="/history">
                <button>Historique</button>
            </Link>
        </section>

    );
};

export default Shop;