import React from 'react';
import { Link } from 'react-router-dom';

const History = () => {
    const expenses = [
        { date: '2024-01-01', nature: 'Déblocage de chapitre', amount: 10 },
        { date: '2024-01-15', nature: 'Réécriture de chapitre', amount: 5 },
    ];

    const revenues = [
        { date: '2024-01-10', nature: 'Achat', amount: 50, realMoney: '5.00 €' },
        { date: '2024-01-20', nature: 'Récupération automatique', amount: 20 },
    ];

    return (
        <section>
            <h1 className="responsive uppercase">Historique</h1>
            <div className="button-container">
                <Link to="/shop">
                    <button>Retour</button>
                </Link>
                <Link to="/contact">
                    <button>Faire une réclamation</button>
                </Link>
            </div>

            <h2>Dépenses de Cristaux</h2>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Nature</th>
                        <th>Montant (Cristaux)</th>
                    </tr>
                </thead>
                <tbody>
                    {expenses.map((expense, index) => (
                        <tr key={index}>
                            <td>{expense.date}</td>
                            <td>{expense.nature}</td>
                            <td>{expense.amount}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <h2>Revenus de Cristaux</h2>
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Nature</th>
                        <th>Montant (Cristaux)</th>
                        <th>Montant (€)</th>
                    </tr>
                </thead>
                <tbody>
                    {revenues.map((revenue, index) => (
                        <tr key={index}>
                            <td>{revenue.date}</td>
                            <td>{revenue.nature}</td>
                            <td>{revenue.amount}</td>
                            <td>{revenue.realMoney || '-'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </section>
    );
};

export default History;