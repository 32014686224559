import React, { useState } from 'react';
import { auth, firestore } from '../config/firebase';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SignUpForm = ({ setCurrentUser }) => {
    const [identifiant, setIdentifiant] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [acceptPolitique, setAcceptPolitique] = useState(false);
    const [errors, setErrors] = useState({
        identifiant: '',
        email: '',
        password: '',
        confirmPassword: '',
        acceptPolitique: '',
        firebase: '',
    });
    const navigate = useNavigate();

    const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

    const handleSignUp = async (e) => {
        e.preventDefault();
        let hasError = false;

        if (!identifiant.trim()) {
            setErrors((prevState) => ({ ...prevState, identifiant: 'Ce champ est obligatoire.' }));
            hasError = true;
        } else {
            setErrors((prevState) => ({ ...prevState, identifiant: '' }));
        }

        if (!email.trim() || !isValidEmail(email)) {
            setErrors((prevState) => ({ ...prevState, email: 'Adresse email non valide.' }));
            hasError = true;
        } else {
            setErrors((prevState) => ({ ...prevState, email: '' }));
        }

        if (!password.trim() || password.length < 6) {
            setErrors((prevState) => ({ ...prevState, password: 'Le mot de passe doit faire au moins 6 caractères.' }));
            hasError = true;
        } else {
            setErrors((prevState) => ({ ...prevState, password: '' }));
        }

        if (password !== confirmPassword) {
            setErrors((prevState) => ({ ...prevState, confirmPassword: 'Les mots de passe ne correspondent pas.' }));
            hasError = true;
        } else {
            setErrors((prevState) => ({ ...prevState, confirmPassword: '' }));
        }

        if (!acceptPolitique) {
            setErrors((prevState) => ({
                ...prevState, acceptPolitique: 'Vous devez accepter les conditions d\'utilisation pour vous inscrire.'
            }));
            hasError = true;
        } else {
            setErrors((prevState) => ({ ...prevState, acceptPolitique: '' }));
        }

        if (!hasError) {
            try {
                const userCredential = await auth.createUserWithEmailAndPassword(email, password);
                const { user } = userCredential;
                await createUserDocument(user, { displayName: identifiant });
                setCurrentUser({ displayName: identifiant, email });
                setIdentifiant('');
                setEmail('');
                setPassword('');
                setConfirmPassword('');
                setAcceptPolitique(false);
                setErrors({
                    identifiant: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    acceptPolitique: '',
                    firebase: '',
                });
                navigate('/account');
            } catch (error) {
                if (error.code === 'auth/email-already-in-use') {
                    setErrors((prevState) => ({
                        ...prevState,
                        firebase: 'L\'adresse email est déjà utilisée par un autre compte.'
                    }));
                } else {
                    setErrors((prevState) => ({
                        ...prevState,
                        firebase: 'Une erreur s\'est produite lors de la création du compte.'
                    }));
                }
                console.error('Erreur lors de la création du compte :', error);
            }
        }
    };

    const createUserDocument = async (user, additionalData) => {
        if (!user) return;
        const userRef = firestore.doc(`users/${user.uid}`);
        const snapshot = await userRef.get();
        if (!snapshot.exists) {
            const { displayName, email } = user;
            const createdAt = new Date();
            try {
                await userRef.set({
                    displayName,
                    email,
                    createdAt,
                    ...additionalData
                });
            } catch (error) {
                console.error('Erreur lors de la création du document utilisateur :', error);
            }
        }
    };

    return (
        <form className="form-container" onSubmit={handleSignUp}>
            <div className="input-field">
                <label htmlFor="identifiant">Identifiant</label>
                <input
                    type="text"
                    id="identifiant"
                    value={identifiant}
                    onChange={(e) => setIdentifiant(e.target.value)}
                />
                {errors.identifiant && <div className="orange italic">{errors.identifiant}</div>}
            </div>
            <div className="input-field">
                <label htmlFor="email">Adresse mail</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && <div className="orange italic">{errors.email}</div>}
            </div>
            <div className="input-field">
                <label htmlFor="password">Mot de passe</label>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                {errors.password && <div className="orange italic">{errors.password}</div>}
            </div>
            <div className="input-field">
                <label htmlFor="confirmPassword">Confirmation de mot de passe</label>
                <input
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {errors.confirmPassword && <div className="orange italic">{errors.confirmPassword}</div>}
            </div>
            <div>
                <label>
                    <input
                        type="checkbox"
                        checked={acceptPolitique}
                        onChange={(e) => setAcceptPolitique(e.target.checked)}
                    />
                    En m'inscrivant sur KURO NO MASTER, j'ai pris connaissance et accepte :
                    <ul>
                        <li>la <a href="/privacy-policy" target="_blank">politique de confidentialité <FontAwesomeIcon icon="fa-solid fa-up-right-from-square" size="xs" /></a></li>
                        <li>les <a href="/terms-of-service" target="_blank">CGU <FontAwesomeIcon icon="fa-solid fa-up-right-from-square" size="xs" /></a></li>
                        <li>les <a href="/terms-of-sales" target="_blank">CGV <FontAwesomeIcon icon="fa-solid fa-up-right-from-square" size="xs" /></a></li>
                    </ul>
                </label>
                {errors.acceptPolitique && <div className="orange italic">{errors.acceptPolitique}</div>}
            </div>
            {errors.firebase && <div className="orange italic">{errors.firebase}</div>}
            <button type="submit" className="primary-button">Inscription</button>
        </form>
    );
};

export default SignUpForm;